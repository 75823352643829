.drag-drop {
  background: #fff;
  border: 1px solid var(--border-color);
  border-radius: 5px;
}
.document-uploader {
  border: 2px dashed #4282fe;
  background-color: #f4fbff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  /* height: 300px; */
}

.document-uploader.active {
  border-color: #6dc24b;
}

.document-uploader .upload-info {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.document-uploader .upload-info div p {
  margin: 0;
  font-size: 16px;
}

.document-uploader .upload-info div p:first-child {
  font-weight: bold;
}

.document-uploader .upload-info svg {
  font-size: 36px;
  margin-right: 1rem;
}

.document-uploader .file-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 20px;
}

.document-uploader .file-list__container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.document-uploader .file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.document-uploader .file-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 1;
}

.document-uploader .file-info p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.document-uploader .file-actions {
  cursor: pointer;
}

.document-uploader .file-actions svg {
  font-size: 18px;
  color: #888;
}

.document-uploader .file-actions svg:hover {
  color: #d44;
}

.document-uploader .browse-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 1px solid #fab768;
  border-radius: 5px;
  cursor: pointer;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  min-height: 40px;
  font-weight: bold;
  color: white;
}
.document-uploader .browse-btn:hover {
  background: transparent;
  color: #fab768;
}

.document-uploader .success-file {
  display: flex;
  align-items: center;
  color: #6dc24b;
}

.document-uploader .success-file p {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.document-uploader input[type="file"] {
  display: none;
}
