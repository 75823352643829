.ck-editor__editable_inline {
  min-height: 300px;
}
.react-datepicker-wrapper{
  width: 100%;
}
.emergency-form-main{
  max-width: 1100px;
  margin: 0 auto;
  background-color: #fff;
  padding: 22px;
  border-radius: 7px;
}
.Mui-selected {
  color: #f87017 !important;
}
.MuiTabs-indicator {
  background-color: #f76f17 !important;
}