/* .home-visit-appointment {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .card {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  } */
  
  .map-card {
    width: 100%;
  }
  
  /* .nurses-list, .drivers-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .nurse-card, .driver-card {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 80px;
    text-align: center;
    cursor: -webkit-grab;
    cursor: grab;
    background-color: #f9f9f9;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  }
  
  .nurse-card img, .driver-card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .nurse-card p, .driver-card p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #333;
  } */
  