@media print {
    /* Hide modal overlay and unnecessary elements */
    .MuiDialogTitle-root,
    .MuiDialogActions-root,
    .no-print {
      display: none;
    }
  
    /* Ensure only the visible content is printed */
    .printable-modal-content {
      display: block;
      width: 100%;
      height: auto;
      overflow: visible; /* Ensure everything fits */
    }
  
    /* Hide elements that are conditionally not rendered */
    .hidden {
      display: none !important;
    }
  
    /* Set A4 page size for print */
    body {
      width: 210mm;
      height: 297mm;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  }
  