.claimsSubmission_container {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  font-family: sans-serif;
}
.claimsSubmission_page {
  /* background: var(--white, #fff); */
  background-color: transparent;
  font-family: sans-serif;
}

.pre_auth_page .pre_auth_content {
  /* display: flex;
  justify-content: space-between; */
}

.pre_auth_page .pre_auth_content .pre_auth_title span {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_page .pre_auth_content .track_pre_auth {
  margin-top: 4px;
}

.pre_auth_page .pre_auth_content .track_pre_auth span {
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.pre_auth_page .pre_auth_content .create_pre_auth {
  display: flex;
  gap: 12px;
}

.pre_auth_page .pre_auth_content .create_pre_auth .download .btn,
.search-filter .apply-filter .btn {
  border-radius: 4px;
  border: 2px solid var(--primary-grey-1, #4d4d53);
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  gap: 3px;
  font-family: sans-serif;
}

.pre_auth_page .pre_auth_content .create_pre_auth .create-btn {
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  max-height: 40px;
  padding: 14px 24px 14px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.pre_auth_page .pre_auth_content .create_pre_auth .create-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_page {
  padding: 20px 24px 19px 24px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  font-family: sans-serif;
}

.search-filter {
  display: flex;
  justify-content: end;
  margin-right: 24px;
  margin-top: 19px;
  margin-bottom: 19px;
  gap: 14px;
}

.search-filter .search {
  position: relative;
}

.search-filter .search input[type="search"] {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="%23242429" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.5 17.5L13.875 13.875" stroke="%23242429" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 20px 20px;
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  background-color: var(--neutrals-input-fields, #f4f6f8);
  border: 0;
  height: 40px;
  width: 292px;
}

.super-git-table tbody .outcome-error {
  border-radius: 6px;
  background: var(--primary-orange-5, #fef2ec);
  padding: 8px 12px 8px 12px;
  color: var(--primary-orange-1, #f5793b);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.super-git-table tbody .outcome-complete {
  border-radius: 6px;
  background: var(--primary-orange-5, #cbf5d2);
  padding: 8px 12px 8px 12px;
  color: var(--primary-orange-1, #04a41c);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.super-git-table tbody .queued {
  border-radius: 6px;
  background: var(--accents-yellow-4, #ffddab);
  padding: 8px 19px 8px 18px;
  color: var(--accents-yellow-1, #ffab2c);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_detail_page .pre_auth_details_top,
.pre_auth_communication_page .pre_auth_details_top {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 20px 0 19px 20px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  margin-bottom: 24px;
}

.pre_auth_detail_page .pre_auth_details_top .back,
.pre_auth_communication_page .pre_auth_details_top .back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  content: "";
  background: #f2b9b3;
  border-radius: 50%;
  cursor: pointer;
}

.pre_auth_detail_page .pre_auth_details_top .pre_auth_details_title span,
.pre_auth_communication_page
  .pre_auth_details_top
  .pre_auth_details_title
  span {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_detail_page .pre_auth_details_top .pre_auth_details_title,
.pre_auth_communication_page .pre_auth_details_top .pre_auth_details_title {
  margin-bottom: 5px;
}

.pre_auth_detail_page .pre_auth_details_top .pre_auth_details_content span,
.pre_auth_communication_page
  .pre_auth_details_top
  .pre_auth_details_content
  span {
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.pre_auth_detail_page .pre_auth_details_nav_menu_,
.pre_auth_communication_page .pre_auth_details_nav_menu_ {
  border-bottom: 1px solid #e1e5e8;
  width: max-content;
}

.pre_auth_detail_page .pre_auth_details_nav_menu ul,
.pre_auth_communication_page .pre_auth_details_nav_menu ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 10px;
}

.pre_auth_detail_page .pre_auth_details_nav_menu ul li,
.pre_auth_communication_page .pre_auth_details_nav_menu ul li {
  padding: 6px 16px;
  color: var(--primary-orange-2, #f5925b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.pre_auth_detail_page .pre_auth_details_nav_menu ul li.active,
.pre_auth_communication_page .pre_auth_details_nav_menu ul li.active {
  color: var(--primary-orange-1, #f5793b);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  background: var(--white, #fff);
  box-shadow: 0px 4px 0px 0px #f5793b;
}

.pre_auth_detail_page,
.pre_auth_communication_page {
  font-family: sans-serif;
}

.pre_auth_detail_page .pre_auth_details_nav_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.pre_auth_detail_page
  .pre_auth_details_nav_menu
  .pre_auth_details_menu_btns
  .ucaf {
  width: 100px;
  height: 40px;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  color: var(--neutrals-white, #fcfdff);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}

.pre_auth_detail_page .pre_auth_details_nav_menu .pre_auth_details_menu_btns {
  display: flex;
  gap: 16px;
}

.pre_auth_detail_page
  .pre_auth_details_nav_menu
  .pre_auth_details_menu_btns
  .follow-up {
  border-radius: 4px;
  background: var(--neutrals-black, #242429);
  color: var(--neutrals-white, #fcfdff);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  width: 114px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pre_auth_communication_page .pre_auth_details_nav_menu {
  margin-bottom: 40px;
}

.pre_auth_communication_page .add_communication .add_communication_header_1,
.pre_auth_communication_page .add_communication .add_communication_header_2 {
  display: flex;
  align-items: center;
  gap: 16px;
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_communication_page
  .add_communication
  .add_communication_header_2
  svg {
  cursor: pointer;
}

.pre_auth_communication_page .add_communication .add_communication_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 38px;
}

.pre_auth_communication_page .add_communication {
  padding: 20px 22px 46px 22px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  height: 100%;
  min-height: 400px;
}

.pre_auth_communication_page
  .add_communication
  .pre_auth_communication_table
  table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.pre_auth_communication_page
  .add_communication
  .pre_auth_communication_table
  table
  thead {
  border-radius: 10px 10px 0px 0px;
  background: var(--accents-yellow-1, #ffab2c);
}

.pre_auth_communication_page
  .add_communication
  .pre_auth_communication_table
  table
  thead
  th {
  padding: 23px 0 22px 30px;
  text-align: left;
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_communication_page
  .add_communication
  .pre_auth_communication_table
  table
  thead
  th:first-child {
  border-radius: 8px 0 0 0;
}

.pre_auth_communication_page
  .add_communication
  .pre_auth_communication_table
  table
  thead
  th:last-child {
  border-radius: 0 8px 0 0;
}

.pre_auth_communication_page
  .add_communication
  .pre_auth_communication_table
  tbody
  td {
  padding: 25px 0 29px 30px;
  text-align: left;
  color: var(--neutrals-ink, #485361);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_communication_page
  .add_communication
  .pre_auth_communication_table
  tbody
  td.user-action {
  cursor: pointer;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_head_cont {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 15px 0 14px 30px;
  border-radius: 10px 10px 0px 0px;
  background: var(--accents-yellow-1, #ffab2c);
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_head_cont
  span {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont {
  padding: 33px 35px 89px 31px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont_inner.row_1,
.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont_inner.row_2 {
  padding-bottom: 20px;
  margin-bottom: 19px;
  border-bottom: 1px solid #dad6d6;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont_inner.row_3 {
  padding-bottom: 20px;
  border-bottom: 1px solid #dad6d6;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont_inner {
  display: flex;
  gap: 108px;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont
  .pre_auth_encounter_user_data.width_247 {
  width: 100%;
  max-width: 247px;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont
  .pre_auth_encounter_user_data.width_254 {
  width: 100%;
  max-width: 254px;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont
  .pre_auth_encounter_user_data.width_239 {
  width: 100%;
  max-width: 239px;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont
  .pre_auth_encounter_user_data.width_161 {
  width: 100%;
  max-width: 161px;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont
  .data_head {
  margin-bottom: 5px;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont
  .data_head
  span {
  color: var(--primary-grey-4, #acacae);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.pre_auth_communication_page
  .pre_auth_encounter_section
  .pre_auth_encounter_body_cont
  .data_cont
  span {
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_detail_page {
  width: 100%;
  /* max-width: 1510px;
  padding: 0px 60px 0px 42px; */
  margin: auto;
  box-sizing: border-box;
}

.eligibility_details_section .eligibility_details_head_cont {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 15px 0 14px 30px;
  border-radius: 10px 10px 0px 0px;
  background: var(--accents-yellow-1, #ffab2c);
}

.eligibility_details_section
  .eligibility_details_body_cont
  .eligibility_details_body_cont_inner {
  display: flex;
  gap: 108px;
}

.eligibility_details_section
  .eligibility_details_body_cont
  .eligibility_details_user_data.width_247 {
  width: 100%;
  max-width: 247px;
}

.eligibility_details_section
  .eligibility_details_body_cont
  .eligibility_details_user_data.width_254 {
  width: 100%;
  max-width: 254px;
}

.eligibility_details_section
  .eligibility_details_body_cont
  .eligibility_details_user_data.width_239 {
  width: 100%;
  max-width: 239px;
}

.eligibility_details_section
  .eligibility_details_body_cont
  .eligibility_details_user_data.width_161 {
  width: 100%;
  max-width: 161px;
}

.eligibility_details_section .eligibility_details_body_cont {
  padding: 33px 47px 42px 35px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.eligibility_details_section .eligibility_details_body_cont_inner.row_1,
.eligibility_details_section .eligibility_details_body_cont_inner.row_2 {
  border-bottom: 1px solid #dad6d6;
}

.eligibility_details_section .eligibility_details_body_cont_inner.row_1 {
  padding-bottom: 19px;
  margin-bottom: 12px;
}

.eligibility_details_section .eligibility_details_body_cont_inner.row_2 {
  padding-bottom: 18px;
  margin-bottom: 13px;
}

.eligibility_details_section .eligibility_details_body_cont .data_head span {
  color: var(--primary-grey-4, #acacae);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.eligibility_details_section .eligibility_details_body_cont .data_head {
  margin-bottom: 5px;
}

.eligibility_details_section .eligibility_details_body_cont .data_cont span {
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.eligibility_details_section {
  /* margin-bottom: 20px; */
  margin-bottom: 0 !important;
}

.eligibility_details_section .eligibility_details_body_cont {
  padding: 27px 30px 72px 30px;
}

.eligibility_details_section
  .eligibility_details_body_cont
  .items_data_accordians {
  padding: 24px 33px 22px 32px;
  border-radius: 10px;
  border: 1px solid #e1e5e8;
  background: #fff;
  margin-bottom: 20px;
}

.eligibility_details_section
  .eligibility_details_body_cont
  .items_data_accordians:last-child {
  margin-bottom: 0;
}

.eligibility_details_section .eligibility_details_body_cont .custom-summary {
  font-weight: bold;
  cursor: pointer;
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eligibility_details_section
  .eligibility_details_body_cont
  .custom-summary
  span {
  color: var(--accents-blue-1, #387ee7);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.eligibility_details_section .eligibility_details_body_cont .expand-icon {
  transition: transform 0.3s;
}

.eligibility_details_section .eligibility_details_body_cont_inner.row_1 {
  padding-bottom: 19px;
  margin-bottom: 12px;
  gap: 95px;
}

.eligibility_details_section .eligibility_details_body_cont_inner.row_2 {
  padding-bottom: 25px;
  margin-bottom: 22px;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont
  .benefits-head {
  margin-bottom: 16px;
  color: var(--primary-orange-1, #f5793b);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_communication_table table {
  width: 100%;
  border-spacing: 0;
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.pre_auth_communication_table table thead {
  border-radius: 10px 10px 0px 0px;
  background: var(--accents-yellow-1, #ffab2c);
}

.pre_auth_communication_table table thead th {
  padding: 23px 0 22px 30px;
  text-align: left;
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_communication_table table thead th:first-child {
  border-radius: 8px 0 0 0;
}

.pre_auth_communication_table table thead th:last-child {
  border-radius: 0 8px 0 0;
}

.pre_auth_communication_table tbody td {
  padding: 25px 0 29px 30px;
  text-align: left;
  color: var(--neutrals-ink, #485361);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_communication_table tbody td.user-action {
  cursor: pointer;
}

.pre_auth_detail_section {
  padding: 31px 22px 36px 20px;
}

.pre_auth_detail_section .pre_auth-detail_section_head span {
  color: #344054;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_detail_section .pre_auth-detail_section_head {
  margin-bottom: 36px;
}

.pre_auth_detail_section {
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  margin-bottom: 20px;
}

.pre_auth_detail_page .eligibility_details_section_total {
  border-radius: 8px;
  border: 1px solid #387ee7;
  background: rgba(56, 126, 231, 0.1);
  margin-bottom: 20px;
  padding: 34px 35px 92px 31px;
}

.pre_auth_detail_page .eligibility_details_section_total .total_head {
  margin-bottom: 21px;
}

.pre_auth_detail_page .eligibility_details_section_total .total_head span {
  color: #344054;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_detail_page .eligibility_details_body_cont_inner {
  display: flex;
  gap: 108px;
}

.pre_auth_detail_page .eligibility_details_body_cont_inner.row_1 {
  border-bottom: 1px solid #dad6d6;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.pre_auth_detail_page .eligibility_details_user_data.width_247 {
  width: 100%;
  max-width: 247px;
}

.pre_auth_detail_page .eligibility_details_user_data.width_254 {
  width: 100%;
  max-width: 254px;
}

.pre_auth_detail_page .eligibility_details_user_data.width_239 {
  width: 100%;
  max-width: 239px;
}

.pre_auth_detail_page .eligibility_details_user_data.width_161 {
  width: 100%;
  max-width: 161px;
}

.eligibility_details_section_total .data_head span {
  color: #387ee7;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.eligibility_details_section_total .data_head {
  margin-bottom: 5px;
}

.eligibility_details_section_total .data_cont span {
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_req_page {
  width: 100%;
  /* max-width: 1510px;
  padding: 0px 60px 0px 42px; */
  margin: auto;
  box-sizing: border-box;
  font-family: sans-serif;
}

.pre_auth_req_page .pre_auth_details_top {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 20px 0 19px 20px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  margin-bottom: 24px;
}

.pre_auth_req_page .pre_auth_details_top .back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  content: "";
  background: #f2b9b3;
  border-radius: 50%;
  cursor: pointer;
}

.pre_auth_req_page .pre_auth_details_top .pre_auth_details_title {
  margin-bottom: 5px;
}

.pre_auth_req_page .pre_auth_details_top .pre_auth_details_title span {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_req_page .pre_auth_details_top .pre_auth_details_content span {
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.pre_auth_req_page .benef_info_head {
  display: flex;
  gap: 16px;
  align-items: center;
}

.pre_auth_req_page .pre_auth_benef_info {
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  padding: 20px 0 32px 20px;
  margin-bottom: 20px;
}

.pre_auth_req_page .benef_info_head span {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_req_page .pre_auth_benef_info .pre_auth_input input {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background: var(--colors-white, #fff);
  padding: 0;
  padding-left: 16px;
  box-sizing: border-box;
  margin-bottom: 0;
}

.pre_auth_req_page
  .pre_auth_benef_info
  .pre_auth_input
  input[type="text"]::placeholder {
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: var(--neutrals-ink, #485361);
  line-height: normal;
}

.pre_auth_req_page .pre_auth_benef_info .pre_auth_input {
  display: flex;
  gap: 20px;
}

.pre_auth_req_page .pre_auth_benef_info .newborn-checks #newborn {
  display: none;
}

.pre_auth_req_page .pre_auth_benef_info .newborn-checks #newborn + label {
  position: relative;
  padding-left: 33px;
  cursor: pointer;
}

.pre_auth_req_page .pre_auth_benef_info .newborn-checks label {
  color: var(--gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.pre_auth_req_page
  .pre_auth_benef_info
  .newborn-checks
  #newborn:checked
  + label::before {
  background-color: var(--primary-orange-4, #fef2ec);
  border: 1px solid var(--primary-orange-1, #f5793b);
}

.pre_auth_req_page
  .pre_auth_benef_info
  .newborn-checks
  #newborn
  + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 20px;
  height: 20px;
  background-color: var(--primary-orange-4, #fef2ec);
  border: 1px solid var(--primary-orange-1, #f5793b);
  border-radius: 50%;
}

.pre_auth_req_page
  .pre_auth_benef_info
  .newborn-checks
  #newborn:checked
  + label::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary-orange-1, #f5793b);
}

.pre_auth_req_page .pre_auth_benef_info .pre_auth_checkbox {
  margin-top: 26px;
  margin-bottom: 26px;
  display: flex;
  align-items: center;
  gap: 47px;
}

.pre_auth_req_page
  .pre_auth_benef_info
  .toggle-button-ref
  input[type="checkbox"] {
  display: none;
}

.pre_auth_req_page .pre_auth_benef_info .toggle-button-ref label {
  display: inline-block;
  position: relative;
  width: 46px;
  height: 24px;
  cursor: pointer;
}

.pre_auth_req_page
  .pre_auth_benef_info
  .toggle-button-ref
  input[type="checkbox"]:checked
  + label:before {
  background-color: #f5793b;
}

.pre_auth_req_page .pre_auth_benef_info .toggle-button-ref label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 46px;
  height: 24px;
  border-radius: 12px;
  background-color: #f5ab85;
  opacity: 0.8;
  transition: transform 0.3s ease;
}

.pre_auth_req_page
  .pre_auth_benef_info
  .toggle-button-ref
  input[type="checkbox"]:checked
  + label:after {
  transform: translateX(22px);
}

.pre_auth_req_page .pre_auth_benef_info .toggle-button-ref label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s ease;
}

.pre_auth_req_page .pre_auth_benef_info .toggle-button-ref {
  display: flex;
  align-items: center;
  gap: 16px;
}

.pre_auth_req_page .pre_auth_benef_info .toggle-button-ref span {
  color: var(--gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.pre_auth_req_page .personal-data {
  border-radius: 8px;
  padding: 20px 20px 51px 20px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  margin-bottom: 36px;
}

.pre_auth_req_page .personal-data .personal-title {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 28px;
  position: relative;
}

.pre_auth_req_page .personal-data .personal-title .personal-head span {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* .pre_auth_req_page .personal-data .personal-fields {
  display: flex;
  gap: 28px 16px;
  flex-wrap: wrap;
} */

.pre_auth_req_page .personal-data .personal-fields input,
.pre_auth_req_page .personal-data .personal-fields select {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background: var(--colors-white, #fff);
  padding: 0;
  padding-left: 16px;
  box-sizing: border-box;
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* .pre_auth_req_page .personal-data .personal-fields input#date {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><g opacity='0.6'><path d='M15.8333 3.3335H4.16667C3.24619 3.3335 2.5 4.07969 2.5 5.00016V16.6668C2.5 17.5873 3.24619 18.3335 4.16667 18.3335H15.8333C16.7538 18.3335 17.5 17.5873 17.5 16.6668V5.00016C17.5 4.07969 16.7538 3.3335 15.8333 3.3335Z' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M13.3333 1.66626V4.99959' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M6.66724 1.66626V4.99959' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M2.5 8.33325H17.5' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></g></svg>");
  background-repeat: no-repeat;
  background-position: right 20px center;
} */

.pre_auth_req_page .personal-data .personal-fields select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><g opacity='0.6'><path d='M5 7.5L10 12.5L15 7.5' stroke='%23485361' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/></g></svg>");
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.pre_auth_req_page .personal-data .supporting_btn,
.pre_auth_req_page .personal-data .add_item_btn {
  border-radius: 4px;
  background: var(--primary-orange-1, #f5793b);
  position: absolute;
  right: 0;
  top: 0px;
}

.pre_auth_req_page .personal-data .supporting_btn button,
.pre_auth_req_page .personal-data .add_item_btn button {
  display: flex;
  align-items: center;
  border: 0;
  background-color: unset;
  gap: 4px;
  width: 100%;
  min-width: 276px;
  height: 40px;
  justify-content: center;
  color: var(--neutrals-pure-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}

table td.action {
  cursor: pointer;
}

.pre_auth_req_page .personal-data .add_item_btn button {
  min-width: 139px;
}

.pre_auth_req_page table thead th {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  border-bottom-width: 0;
  vertical-align: middle;
  text-align: center;
}

.pre_auth_req_page table tbody td {
  color: var(--neutrals-ink, #485361);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_req_page .encounter-fields {
  margin-top: 28px;
  margin-bottom: 24px;
}

.pre_auth_req_page .encounter-fields span {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_req_page .care_teams {
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  padding: 25px 22px 55px 20px;
  margin-bottom: 20px;
}

.pre_auth_req_page .care_teams .personal-title,
.pre_auth_req_page .care_teams .add_new {
  display: flex;
  align-items: center;
  gap: 16px;
}

.pre_auth_req_page .care_teams .personal-head span {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_req_page .care_teams .add_new span {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_req_page .care_teams .flex_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 33px;
}

.pre_auth_req_page .care_teams table tbody input[type="text"],
.pre_auth_req_page .care_teams table tbody select {
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background: var(--colors-white, #fff);
  width: 94%;
  height: 40px;
  padding-left: 16px;
  color: var(--neutrals-ink, #485361);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pre_auth_req_page .care_teams table tbody select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><g opacity='0.6'><path d='M5 7.5L10 12.5L15 7.5' stroke='%23485361' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/></g></svg>");
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.pre_auth_req_page .care_teams table tbody input[type="text"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3E%3Cg opacity='0.6'%3E%3Cpath d='M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z' stroke='%23485361' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.2502 19.2502L15.2627 15.2627' stroke='%23485361' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.pre_auth_req_page .care_teams .add_new.addNewBtn,
.pre_auth_req_page .care_teams table .remove-row {
  cursor: pointer;
}

.modal-content .search-fields input[type="search"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3E%3Cg opacity='0.6'%3E%3Cpath d='M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z' stroke='%23485361' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3Cpath d='M19.25 19.2502L15.2625 15.2627' stroke='%23485361' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 20px center;
  color: var(--neutrals-ink, #485361);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal.open {
  right: 0;
  display: block;
}

.modal {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  transition: right 0.3s ease-in-out;
  display: none;
}

.modal-content {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  height: -webkit-fill-available;
  background-color: #fff;
  padding: 23px 26px;
  overflow-y: auto;
}

.modal-content .close {
  display: flex;
  align-items: center;
  position: relative;
  gap: 101px;
  margin-bottom: 37px;
}

.modal-content .close svg {
  position: absolute;
  left: 8px;
  cursor: pointer;
}

.modal-content .close:before {
  width: 40px;
  height: 40px;
  content: "";
  background: #f2b9b3;
  border-radius: 50%;
  display: block;
  cursor: pointer;
}

.modal-content .close .bene-text {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: sans-serif;
}

.item_detail_boxes .detail_box_row_1 {
  display: flex;
  gap: 25px;
}

.item_detail_boxes .detail_box_row_1 .detail_box.width_172 {
  width: 172px;
  padding-left: 30px;
}

.item_detail_boxes .detail_box_row_1 .detail_box.width_254 {
  width: 254px;
  padding-left: 30px;
}

.detail_box_row_1.border_ {
  border-bottom: 1px solid #dad6d6;
  padding-bottom: 19px;
  margin-bottom: 12px;
}

.item_detail_box_1 {
  margin-bottom: 28px;
}

.item_detail_box_1:hover,
.item_detail_box_2:hover {
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 4px 32px 0px rgba(222, 227, 240, 0.72);
  padding: 24px 18px 40px 7px;
}

.pre_auth_detail_page .title span {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pre_auth_detail_page .title {
  margin-bottom: 24px;
}

.pre_auth_detail_page .detail_head {
  color: var(--primary-grey-4, #acacae);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
}

.pre_auth_detail_page .detail_cont {
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.pre_auth_req_page .modal-content {
  padding: 23px 29px 53px 29px;
}

.pre_auth_req_page .add_item_fields .field_row {
  display: block;
  gap: 24px;
}

.pre_auth_req_page .add_item_fields .field_row._9 {
  gap: 16px;
}

.pre_auth_req_page .add_item_fields .field_two {
  gap: 17px;
  display: block;
}

.pre_auth_req_page .add_item_fields .box_field .field_row,
.pre_auth_req_page .add_item_fields .box_field_2 .field_row {
  margin-bottom: 20px;
}

.pre_auth_req_page .add_item_fields .box_field .field_row._6,
.pre_auth_req_page .add_item_fields .box_field .field_row._10 {
  margin-bottom: 0px;
}
.rmsc .dropdown-container,
.pre_auth_req_page .add_item_fields .field_row input,
.pre_auth_req_page .add_item_fields select {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0) !important;
  background: var(--colors-white, #fff);
  padding: 0;
  padding-left: 16px;
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
}

.pre_auth_req_page .add_item_fields .field input {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0) !important;
  background: var(--colors-white, #fff);
  padding: 0;
  padding-left: 16px;
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
}

.rmsc .dropdown-heading {
  height: 40px;
}

.pre_auth_req_page
  .add_item_fields
  .box_field
  .field_row._1
  input[type="search"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3E%3Cg opacity='0.6'%3E%3Cpath d='M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z' stroke='%23485361' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M19.2502 19.2502L15.2627 15.2627' stroke='%23485361' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.pre_auth_req_page .add_item_fields .field_row select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><g opacity='0.6'><path d='M5 7.5L10 12.5L15 7.5' stroke='%23485361' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/></g></svg>");
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.pre_auth_req_page .add_item_fields .box_field_2 {
  /* margin-top: 41px; */
  margin-bottom: 61px;
}

.pre_auth_req_page .add_item_fields .box_field_2 .width_172 {
  width: 100%;
}

.pre_auth_req_page .add_item_fields .box_field_2 .field_two input {
  width: 100%;
}

.pre_auth_req_page .modal-content .request-eligible-btns {
  display: flex;
  align-items: center;
  gap: 20px;
}

.pre_auth_req_page .modal-content .request-eligible-btns .cancel-btn {
  width: 100%;
  max-width: 358px;
  height: 40px;
  border-radius: 5px;
  background: var(--neutrals-black, #242429);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pre_auth_req_page .modal-content .request-eligible-btns .request-btn {
  width: 100%;
  max-width: 358px;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pre_auth_req_page .modal-content .request-eligible-btns .cancel-btn span,
.pre_auth_req_page .modal-content .request-eligible-btns .request-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_req_page
  .add_item_fields
  .field_row
  input[type="date"]::-moz-calendar-picker-indicator {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><g opacity='0.6'><path d='M15.8333 3.3335H4.16667C3.24619 3.3335 2.5 4.07969 2.5 5.00016V16.6668C2.5 17.5873 3.24619 18.3335 4.16667 18.3335H15.8333C16.7538 18.3335 17.5 17.5873 17.5 16.6668V5.00016C17.5 4.07969 16.7538 3.3335 15.8333 3.3335Z' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M13.3333 1.66626V4.99959' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M6.66724 1.66626V4.99959' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M2.5 8.33325H17.5' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></g></svg>");
}

.pre_auth_req_page .add_item_fields input[type="date"] {
  padding-right: 20px;
}

.pre_auth_req_page
  .add_item_fields
  .field_row
  input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><g opacity='0.6'><path d='M15.8333 3.3335H4.16667C3.24619 3.3335 2.5 4.07969 2.5 5.00016V16.6668C2.5 17.5873 3.24619 18.3335 4.16667 18.3335H15.8333C16.7538 18.3335 17.5 17.5873 17.5 16.6668V5.00016C17.5 4.07969 16.7538 3.3335 15.8333 3.3335Z' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M13.3333 1.66626V4.99959' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M6.66724 1.66626V4.99959' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M2.5 8.33325H17.5' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></g></svg>");
}

.pre_auth_communication_page .modal-content {
  padding: 23px 24px 0 24px;
  max-width: fit-content;
}

.pre_auth_communication_page .modal-content input[type="text"],
.pre_auth_communication_page .modal-content select {
  width: 399px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background: var(--colors-white, #fff);
  padding: 0;
  padding-left: 16px;
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
}

.pre_auth_communication_page .modal-content select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><g opacity='0.6'><path d='M5 7.5L10 12.5L15 7.5' stroke='%23485361' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/></g></svg>");
  background-repeat: no-repeat;
  background-position: right 20px center;
  margin-bottom: 21px;
}

.pre_auth_communication_page .modal-content input[type="text"] {
  margin-bottom: 20px;
}

/* Hide the default file input */
.pre_auth_communication_page input#fileUpload {
  display: none;
}

/* Style the custom file upload label to look like a button */
.pre_auth_communication_page label.file-label {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px dashed var(--primary-grey-1, #4d4d53);
  background: var(--neutrals-input-fields, #f4f6f8);
  width: 400px;
  height: 115px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

/* Highlight the label when hovered */
.pre_auth_communication_page label.file-label:hover {
  background: #eaeaea;
}

/* Drag and drop title */
.pre_auth_communication_page label.file-label #dropTitle {
  display: block;
  margin-bottom: 10px;
}

/* "Choose File" text */
.pre_auth_communication_page label.file-label #chooseFileText {
  display: block;
  margin-bottom: 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--gray-900, #101828);
}

/* Choose File button */
.pre_auth_communication_page label.choose-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: var(--primary-color, #007bff);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

/* Hide the default file input text that says "No file chosen" */
.pre_auth_communication_page
  input#fileUpload
  + label.file-label
  #chooseFileText {
  display: block;
}

/* Show the selected file name */
.pre_auth_communication_page
  input#fileUpload.has-file
  + label.file-label
  #chooseFileText {
  display: none;
}

.pre_auth_communication_page
  input#fileUpload.has-file
  + label.file-label
  #fileNameText {
  display: block;
  font-weight: bold;
  margin-top: 10px;
}

/* Optional: Style the file info div */
.pre_auth_communication_page #fileInfo {
  margin-top: 16px;
  font-size: 14px;
  color: #555;
  margin-bottom: 16px;
}

.pre_auth_communication_page span#fileNameText {
  border-radius: 4px;
  background: var(--primary-grey-4, #acacae);
  width: 106px;
  height: 40px;
  display: block;
  margin: auto;
  line-height: 2.9;
  color: var(--neutrals-white, #fcfdff);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_communication_page #fileInfo {
  margin-top: 16px;
  font-size: 14px;
  color: #555;
  margin-bottom: 16px;
  border-radius: 6px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background: var(--neutrals-input-fields, #f4f6f8);
  width: fit-content;
  padding: 6px 8px;
}

.pre_auth_communication_page #fileIcon {
  display: none;
  vertical-align: middle;
  margin-left: 10px;
}

/* Show the SVG icon when a file name exists */
.pre_auth_communication_page
  input#fileUpload.has-file
  + label.file-label
  #fileNameText
  + #fileInfo
  #fileIcon {
  display: inline-block;
}

.pre_auth_communication_page #fileInfo span#fileName {
  color: var(--neutrals-ink, #485361);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.pre_auth_communication_page .modal-content .search-btn {
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  text-align: center;
  width: 399px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.pre_auth_communication_page .modal-content .reset-btn {
  border-radius: 4px;
  border: 2px solid #242429;
  background: rgba(255, 255, 255, 0.08);
  text-align: center;
  width: 395px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pre_auth_communication_page .modal-content .search-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.pre_auth_communication_page .modal-content .reset-btn span {
  color: var(--neutrals-black, #242429);
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.pre_auth_communication_page .add-btn span {
  width: 100%;
  max-width: 140px;
  height: 40px;
  display: block;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: var(--neutrals-black, #242429);
  color: var(--neutrals-white, #fcfdff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.pre_auth_communication_page .add-btn {
  margin-bottom: 46px;
  cursor: pointer;
}

.table-not-scorll .table-hover thead {
  background: #e9ebf0;
  box-shadow: 0px 0.88158px 0px 0px rgba(214, 215, 220, 0.5);
  height: 71px;
}

.truncate {
  white-space: unset !important;
}

.gradient-background {
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
}

.invoice-text-field .MuiInputBase-root .MuiInputBase-input:focus {
  box-shadow: none !important;
}
