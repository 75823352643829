p {
  margin: 0;
  padding: 0;
}
.ft10 {
  font-size: 34px;
  /* font-family: Times; */
  color: #333333;
}
.ft11 {
  font-size: 17px;
  /* font-family: Times; */
  color: #333333;
}
.ft12 {
  font-size: 14px;
  /* font-family: Times; */
  color: #333333;
}
.ft13 {
  font-size: 14px;
  /* font-family: Times; */
  color: #333333;
}
.ft14 {
  font-size: 14px;
  line-height: 27px;
  /* font-family: Times; */
  color: #333333;
}
.ft15 {
  font-size: 14px;
  line-height: 27px;
  /* font-family: Times; */
  color: #333333;
}
