/* Base styles for mobile-first approach */

.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  font-family: Arial, sans-serif;
  min-width: 1000px; /* Ensure table is wide enough to trigger scrolling on mobile */
}

.custom-table td {
  padding: px px; /* Reduce padding for mobile */
  font-weight: bold;
  color: #333;
  background-color: #d9d9d9;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  font-size: 12px;
}

.custom-table tbody tr td:first-child {
  border-left: 1px solid black;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-clip: padding-box;
  overflow: hidden;
}

.custom-table tbody tr td:last-child {
  border-right: 1px solid black;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-clip: padding-box;
  overflow: hidden;
}

/* Adjust for th elements as well */
.custom-table th {
  background-color: #c6c7c9;
  padding: 10px; /* Reduce padding for mobile */
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.custom-table thead th:first-child {
  border-left: 1px solid black;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 8px;
}

.custom-table thead th:last-child {
  border-right: 1px solid black;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.name-cell {
  display: flex;
  align-items: center;
  font-size: 12px; /* Mobile-first font size */
  text-align: left;
}

.initials {
  width: 30px; /* Reduce size for mobile */
  height: 30px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-size: 12px; /* Reduce font size for mobile */
}

.initials.blue {
  background-color: #3498db;
}

.initials.pink {
  background-color: #e74c3c;
}

.actions {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.edit-btn img,
.delete-btn img {
  width: 14px; /* Reduce icon size for mobile */
  height: 14px;
}

.react-switch {
  display: inline-block;
}

.table-row td {
  padding: 8px; /* Reduce padding for mobile */
  text-align: left; /* Ensure left alignment */
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.navigationbar {
  height: 56px; /* Default height for mobile */
}

/* Custom Scrollbar Styles */
.custom-table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  width: 100%; /* Ensure the container takes full width */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.custom-table-container::-webkit-scrollbar {
  height: 12px; /* Height of the scrollbar for horizontal scrolling */
}

.custom-table-container::-webkit-scrollbar-track {
  background: #ecf0f1; /* Background color of the track */
  border-radius: 10px; /* Rounded corners for the track */
}

.custom-table-container::-webkit-scrollbar-thumb {
  background-color: black; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 3px solid #ecf0f1; /* Border around the thumb */
}

.custom-table-container::-webkit-scrollbar-thumb:hover {
  background-color: black; /* Darker color for hover state */
}

.custom-table-container::-webkit-scrollbar-thumb:active {
  background-color: black; /* Even darker color when active */
}

.table-button {
  background-color: #ffddc7; /* Background color for the button */
  color: #000000; /* Text color */
  border: none; /* No border */
  padding: 2px 20px; /* Padding inside the button */
  border-radius: 20px; /* Rounded corners */
  font-size: 14px; /* Font size */
  font-weight: bold; /* Bold text */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Slight shadow effect */
  text-align: center; /* Center the text */
}

.impersonate-indicator {
  padding: 5px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  color: white;
}

.impersonate-true {
  background-color: #fdddc6;
  color: #333;
}

.impersonate-false {
  background-color: #95a5a6;
  color: #333;
}

.navigationbar-hamburger {
  background: linear-gradient(90deg, #faa613 0%, #ff3333 100%) !important;
  width: 250px; /* Width of the sidebar menu */
  color: #333; /* Text color */
  transition: left 0.3s ease; /* Smooth slide-in transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* shadow effect */
}


/* Media queries for responsiveness */
@media (min-width: 768px) {
  .navigationbar-hamburger {
    width: 300px; /* Wider width for larger screens */
  }
}

@media (max-width: 768px) {
  .navigationbar-hamburger {
    width: 250px; /* Standard width for smaller screens */
  }

  .tablebar div::before {
    content: attr(data-cell);
    font-weight: bold;
    text-transform: capitalize;
    color: rgb(55 65 81);
  }
}

@media (max-width: 480px) {
  .navigationbar-hamburger {
    width: 200px; /* Narrower width for very small screens */
  }
}
/* Media queries for larger screens */

/* sm */
@media (min-width: 640px) {
  .custom-table td {
    padding: 4px 8px; /* Increase padding for small screens */
  }

  .custom-table th {
    font-size: 16px;
    padding: 12px; /* Increase padding for small screens */
  }

  .name-cell,
  .table-row td,
  .custom-table td {
    font-size: 14px; /* Increase font size for small screens */
  }

  .initials {
    width: 35px; /* Increase size for small screens */
    height: 35px;
    font-size: 14px; /* Increase font size for small screens */
  }

  .edit-btn img,
  .delete-btn img {
    width: 16px;
    height: 16px;
  }

  .paste-btn img{
    width: 20px;
    height: 20px;
  }

  .navigationbar {
    height: 64px; /* Adjust height for small screens */
  }
}

/* md: Tablet screens */
@media (min-width: 768px) {
  .custom-table td {
    padding: 4px 8px; /* Increase padding for tablets */
  }

  .custom-table th {
    font-size: 18px;
    padding: 14px; /* Increase padding for tablets */
  }

  .name-cell,
  .table-row td,
  .custom-table td {
    font-size: 16px; /* Increase font size for tablets */
  }

  .navigationbar {
    height: 68px; /* Adjust height for tablets */
  }
}

/* lg: Laptop screens */
@media (min-width: 1024px) {
}

/* xl: Desktop screens */
@media (min-width: 1280px) {
}

/* 2xl: Large desktops */
@media (min-width: 1536px) {
}
