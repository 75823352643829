/* For IE, Edge and Firefox */
/* input , select {
  border: 1px solid #ccc; 
  padding: 8px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out !important; 
  background-color: #ebe9e9; 
}

input:focus {
  border-color: #484949bd !important; 
  background-color: #f4f6f7; 
  outline: none; 
  box-shadow: 0 0 5px rgba(155, 156, 156, 0.438) !important; 
}
 select {
  border: 1px solid #ccc; 
  padding: 8px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out !important; 
  background-color: #f5f2f2 !important; 
}

select:focus {
  border-color: #484949bd !important; 
  background-color: #f4f6f7 !important; 
  outline: none;
  box-shadow: 0 0 5px rgba(244, 245, 245, 0.836) !important; 
} */

input {
  width: 100%;
}

input:focus {
  box-shadow: none !important;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* material ui css */

.css-10ghrmp-MuiPaper-root-MuiAppBar-root {
  background-color: #ffab2c !important;
}

body {
  background-color: #f2f3f4 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #ffab2c !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #ffab2c !important;
}

.custom-pagination-item {
  margin: 0 4px;
  color: #000; /* Default color */
}

.custom-pagination-item.selected {
  background-color: #ffab2c;
  color: white;
  border-radius: 50%;
}

.custom-pagination-item:hover {
  background-color: #ffab2c;
  color: white;
}
.css-98zpv7-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px !important;
}

.heading-group {
  background: #ccc;
  padding: 15px;
  padding-top: 0;
  margin-top: 20px;
}
.heading-group h1 {
  font-weight: 600;
}
.text-group {
  border: solid 1px #ccc;
  padding: 0px 10px 10px;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.text-group label {
  font-weight: 600;
  font-size: 14px;
}
.text-group span {
  font-weight: 400;
  font-size: 14px;
}
.print-pdf {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  display: none;
}

.view-reports-table-th {
  border-right: 1px solid #000 !important;
  padding: 4px;
}
.view-reports-table-td {
  border-right: 1px solid #000 !important;
}

.consent-form-label {
  font-size: 14px;
  white-space: nowrap;
}
.consent-form-input {
  border: 0;
  border-bottom: 1px solid black;
  outline: none;
  width: 70%;
  font-size: 14px;
}
.consent-form-input:focus {
  border-bottom: 1px solid black;
  outline: none;
}
.consent-form-list {
  padding: 0 10px;
}
.consent-form-list li {
  padding: 5px;
  font-size: 14px;
}
.consent-form-input-transparent {
  border: 0;
  width: 100%;
  height: 50px;
  outline: none;
  font-size: 14px;
}
.ql-editor {
  min-height: 150px;
}
.ql-toolbar.ql-snow {
  background: #ffab2c;
}

@media print {
  .print-container {
    overflow: visible !important;
    max-height: unset !important;
  }

  .page-break {
    page-break-before: always;
    break-before: page;
  }

  .heading-group {
    background: #ccc;
    padding: 15px;
    padding-top: 0;
    margin-top: 20px;
  }
  .heading-group h1 {
    font-weight: 600;
  }
  .text-group {
    border: solid 1px #ccc;
    padding: 0px 10px 10px;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .text-group label {
    font-weight: 600;
    font-size: 14px;
  }
  .text-group span {
    font-weight: 400;
    font-size: 14px;
  }

  img {
    max-width: 150px !important;
    height: 150px !important;
  }
}

.chief-compaint-table-new td {
  padding: 12px !important;
}
