.superGit_container {
  width: 100%;
  /* max-width: 1510px;
  padding: 0px 60px 0px 42px; */
  margin: auto;
  box-sizing: border-box;
  font-family: sans-serif;
}

.benefeciaries-page {
  padding: 20px 24px 19px 24px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #666565);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  font-family: sans-serif;
}

.beneficiaries-content {
  /* display: flex;
  justify-content: space-between; */
}

.create-benefeciaries {
  display: flex;
  gap: 12px;
}

.benefeciaries-title span {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.track-beneficiaries span {
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.track-beneficiaries {
  margin-top: 4px;
}

.create-benefeciaries .download .btn,
.search-filter .apply-filter .btn {
  border-radius: 4px;
  border: 2px solid var(--primary-grey-1, #4d4d53);
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  gap: 3px;
  font-family: sans-serif;
}

.create-btn {
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  max-height: 40px;
  padding: 14px 24px 14px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.create-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.search-filter .search {
  position: relative;
}

.search-filter .search input[type="search"] {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="%23242429" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.5 17.5L13.875 13.875" stroke="%23242429" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 20px 20px;
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  background-color: var(--neutrals-input-fields, #f4f6f8);
  border: 0;
  height: 40px;
  width: 292px;
}

.search-filter .search input[type="search"]::-webkit-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-filter .search input[type="search"]:-ms-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-filter .search input[type="search"]::-ms-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-filter .search input[type="search"]::placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-filter {
  display: flex;
  justify-content: end;
  margin-right: 24px;
  margin-top: 19px;
  margin-bottom: 19px;
  gap: 14px;
}

.super-git-table table {
  /* width: 90%; */
  /* border-spacing: 0; */
  font-family: sans-serif;
  
}

.super-git-table {
  overflow-y: scroll;
  /* margin-right: -10px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.super-git-table table thead {
  border-radius: 8px 8px 0px 0px;
  background-color: #151616;
  color: white;
  box-shadow: 0px 0.8815790414810181px 0px 0px rgba(214, 215, 220, 0.5);
}

.super-git-table thead th:first-child {
  border-radius: 8px 0 0 0;
}

.super-git-table thead th:last-child {
  border-radius: 0 8px 0 0;
}

.super-git-table thead th {
  /* padding: 26px 0 21px 42px; */
  background-color: #151616;
  color: white;
  text-align: center;
  white-space: nowrap;
  font-size: 1rem;
}

.super-git-table tbody td {
  /* padding: 30px 0 17px 42px; */
  text-align: center;
}

.super-git-table tbody tr:first-child {
  background: #777879;
  
  box-shadow: 0px 0.8815790414810181px 0.8815790414810181px 0.8815790414810181px
    rgba(214, 215, 220, 0.5);
}

.super-git-table tbody tr {
  background-color: floralwhite;
  box-shadow: 0px 0.8815790414810181px 0.8815790414810181px 0.8815790414810181px
    rgba(214, 215, 220, 0.5);
}

.super-git-table tbody .user-action span {
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  transition: right 0.3s ease-in-out;
  display: none;
}

.modal.open {
  right: 0;
  display: block;
}

.modal-content {
  position: absolute;
  top: 0;
  right: 0;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  height: -webkit-fill-available;
  background-color: #fff;
  padding: 23px 26px;
  overflow-y: auto;
}
/* yyy */
.insurance-plann {
}

.modal-content .close:before,
.benefeciary-create .benefeciary-top .back,
.patient-info .patient-top .back {
  width: 40px;
  height: 40px;
  content: "";
  background: #f2b9b3;
  border-radius: 50%;
  display: block;
  cursor: pointer;
}

.modal-content .close {
  display: flex;
  align-items: center;
  position: relative;
  gap: 101px;
  margin-bottom: 37px;
}

.modal-content .close svg {
  position: absolute;
  left: 8px;
  cursor: pointer;
}

.modal-content .close .bene-text {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: sans-serif;
}

.modal-content .search-fields input,
.modal-content .search-fields select,
.insurance-plann .search-fields input,
.insurance-plann .search-fields select {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background: var(--colors-white, #fff);
  padding: 0;
  /* margin-bottom: 20px; */
  padding-left: 16px;
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
}

.modal-content .search-fields input[type="text"]::-webkit-input-placeholder, .insurance-plann .search-fields input[type="text"]::-webkit-input-placeholder {
  color: var(--neutrals-ink, #485361);
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6000000238418579; */
}

.modal-content .search-fields input[type="text"]:-ms-input-placeholder, .insurance-plann .search-fields input[type="text"]:-ms-input-placeholder {
  color: var(--neutrals-ink, #485361);
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6000000238418579; */
}

.modal-content .search-fields input[type="text"]::-ms-input-placeholder, .insurance-plann .search-fields input[type="text"]::-ms-input-placeholder {
  color: var(--neutrals-ink, #485361);
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6000000238418579; */
}

.modal-content .search-fields input[type="text"]::placeholder,
.modal-content .search-fields select,
.insurance-plann .search-fields input[type="text"]::placeholder,
.insurance-plann .search-fields select {
  color: var(--neutrals-ink, #485361);
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6000000238418579; */
}

.modal-content .search-fields input[type="date"],
.insurance-plann .search-fields input[type="date"] {
  color: var(--neutrals-ink, #485361);
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6000000238418579; */
}

.modal-content .search-btn,
.insurance-plann .search-btn {
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  text-align: center;
  width: 399px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 20px; */
  cursor: pointer;
}

.modal-content .search-btn span,
.insurance-plann .search-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.modal-content .reset-btn .insurance-plann .reset-btn {
  border-radius: 4px;
  border: 2px solid #242429;
  background: rgba(255, 255, 255, 0.08);
  text-align: center;
  width: 399px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-content .reset-btn span,
.insurance-plann .reset-btn span {
  color: var(--neutrals-black, #242429);
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.benefeciary-create {
  font-family: sans-serif;
}

.benefeciary-create .benefeciary-top .back,
.patient-info .patient-top .back {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.benefeciary-create .benefeciary-top {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 20px 0 19px 20px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  /* margin-bottom: 20px; */
}

.benefeciary-create .benefeciary-top .benefeciary-title span {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.benefeciary-create .benefeciary-top .benefeciary-content span {
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.benefeciary-create .patient-details {
  /* padding: 20px 0 46px 20px; */
  padding: 10px 15px !important;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  /* margin-bottom: 20px; */
}

.benefeciary-create .patient-details .details-title {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 30px;
}

.benefeciary-create .patient-details .details-title .note-svg {
  border-radius: 8px;
  background: var(--neutrals-input-fields, #f4f6f8);
  padding: 4px 4px 1px 4px;
}

.benefeciary-create .patient-details .details-title span {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* .benefeciary-create .patient-details .details-fields {
  display: flex;
  align-items: center;
  gap: 20px;
} */

.benefeciary-create .patient-details .details-fields input#national-id {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background: var(--colors-white, #fff);
  padding-left: 16px;
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
}

.benefeciary-create .patient-details .details-fields select#system-type {
  width: 100%;
  height: 40px;
  padding: 0;
  border-width: 0;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background: var(--colors-white, #fff);
  padding-left: 16px;
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.benefeciary-create .patient-details .details-fields .inquire-btn {
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  height: 40px;
  padding: 0px 24px 0px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 200px;
}

.benefeciary-create .patient-details .details-fields .inquire-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.benefeciary-create .patient-details .details-fields select#system-type,
.benefeciary-create .personal-data .personal-fields select,
.benefeciary-create .address .address-fields select#select-country,
.benefeciary-create .new-born .newborn-fields select,
.modal-content .search-fields select,
.insurance-plann .search-fields select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><g opacity='0.6'><path d='M5 7.5L10 12.5L15 7.5' stroke='%23485361' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/></g></svg>");
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 20px;
}

.benefeciary-create .personal-data,
.benefeciary-create .address,
.benefeciary-create .new-born,
.benefeciary-create .insurance-plans {
  border-radius: 8px;
  padding: 20px 20px 44px 20px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  /* margin-bottom: 20px; */
}

.benefeciary-create .insurance-plans {
  margin-bottom: 40px;
}

.benefeciary-create .personal-data .personal-title,
.benefeciary-create .address .address-title,
.benefeciary-create .new-born .newborn-title,
.benefeciary-create .insurance-plans .insurance-flex {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 28px;
}

.benefeciary-create .personal-data .personal-title .personal-head,
.benefeciary-create .address .address-title .address-head,
.benefeciary-create .new-born .newborn-title .new-born-head,
.benefeciary-create .insurance-plans .insurance-title .insurance-head {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.insurance-title .insurance-head {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.benefeciary-create .personal-data .personal-fields input,
.benefeciary-create .address .address-fields input {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background: var(--colors-white, #fff);
  padding: 0;
  padding-left: 16px;
  box-sizing: border-box;
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.benefeciary-create .personal-data .personal-fields select,
.benefeciary-create .address .address-fields select {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background-color: var(--colors-white, #fff);
  padding: 0;
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 16px;
}

.benefeciary-create .new-born .newborn-fields select {
  width: 391px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background-color: var(--colors-white, #fff);
  padding: 0;
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 16px;
}

.benefeciary-create .new-born .newborn-fields {
  display: flex;
  gap: 28px 16px;
  flex-wrap: wrap;
}

/* .benefeciary-create .personal-data .personal-fields,
.benefeciary-create .address .address-fields,
.benefeciary-create .new-born .newborn-fields {
  display: flex;
  gap: 28px 16px;
  flex-wrap: wrap;
} */

.benefeciary-create .personal-data .personal-fields input.date,
.modal-content .search-fields input.date,
.insurance-plann .search-fields input.date {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><g opacity='0.6'><path d='M15.8333 3.3335H4.16667C3.24619 3.3335 2.5 4.07969 2.5 5.00016V16.6668C2.5 17.5873 3.24619 18.3335 4.16667 18.3335H15.8333C16.7538 18.3335 17.5 17.5873 17.5 16.6668V5.00016C17.5 4.07969 16.7538 3.3335 15.8333 3.3335Z' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M13.3333 1.66626V4.99959' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M6.66724 1.66626V4.99959' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M2.5 8.33325H17.5' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></g></svg>");
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.benefeciary-create
  .personal-data
  .personal-fields
  input.date::-moz-calendar-picker-indicator,
.benefeciary-create
  .personal-data
  .personal-fields
  input.date::-ms-calendar-picker-indicator,
.modal-content .search-fields input.date::-webkit-calendar-picker-indicator,
.insurance-plann .search-fields input.date::-webkit-calendar-picker-indicator {
  display: none;
}

.benefeciary-create
  .personal-data
  .personal-fields
  input.date::-webkit-calendar-picker-indicator,
.modal-content .search-fields input.date::-webkit-calendar-picker-indicator,
.insurance-plann .search-fields input.date::-webkit-calendar-picker-indicator {
  display: none;
}

.benefeciary-create .insurance-plans .insurance-title .add-new-btn {
  border-radius: 4px;
  background: var(--primary-orange-1, #f5793b);
  width: 182px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  cursor: pointer;
}

.benefeciary-create .insurance-plans .insurance-title .add-new-btn span {
  color: var(--neutrals-pure-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.benefeciary-create .insurance-plans .insurance-flex {
  margin-bottom: 0;
}

.benefeciary-create .insurance-plans .insurance-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
}

.benefeciary-create .save-benefeciary-btn {
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  width: 440px;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 40px;
}

.benefeciary-create .save-benefeciary-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}
.save-benefeciary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 40px;
}

.modal .modal-content .primary-check,
.insurance-plann .primary-check {
  margin-bottom: 45px;
}

.modal .modal-content .primary-check label,
.insurance-plann .primary-check label {
  color: #344054;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

#primary {
  display: none;
}

#primary + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

#primary + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 20px;
  height: 20px;
  background-color: var(--primary-orange-4, #f5c3ab);
  border: 1px solid var(--primary-orange-1, #f5793b);
  border-radius: 50%;
}

#primary:checked + label::before {
  background-color: var(--primary-orange-4, #f5c3ab);
  border: 1px solid var(--primary-orange-1, #f5793b);
}

#primary:checked + label::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary-orange-1, #f5793b);
}

.modal-content .buttons .cancel-btn,
.insurance-plann .buttons .cancel-btn {
  border-radius: 5px;
  background: var(--neutrals-black, #242429);
  width: 190px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-content .buttons .cancel-btn span,
.modal-content .buttons .save-btn span,
.insurance-plann .buttons .cancel-btn span,
.insurance-plann .buttons .save-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.modal-content .buttons .save-btn,
.insurance-plann .buttons .save-btn {
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  width: 190px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-content .buttons {
  display: flex;
  justify-content: space-between;
}
.insurance-plann .buttons {
  display: flex;
  justify-content: flex-end;
}

.patient-info {
  font-family: sans-serif;
}

.patient-info .patient-top {
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  padding: 20px 526px 19px 20px;
  /* margin-bottom: 20px; */
}

.patient-info .patient-title span {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.patient-info .patient-content span {
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.patient-info .patient-details,
.patient-info .patient-address-details,
.patient-info .patient-insurance-details {
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  padding: 23px 29px 49px 29px;
}

.patient-info .patient-details,
.patient-info .patient-address-details {
  /* margin-bottom: 20px; */
}

.patient-info .patient-details .patient-details-header,
.patient-info .patient-address-details .patient-address-details-header,
.patient-info .patient-insurance-details .patient-insurance-details-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 39px;
}

.patient-info .patient-details .patient-table-data .data-boxes,
.patient-info .patient-address-details .patient-address-table-data .data-boxes,
.patient-info
  .patient-insurance-details
  .patient-insurance-table-data
  .data-boxes {
  display: flex;
  gap: 8px;
  width: 195px;
}

.patient-info .patient-details .patient-table-data .data-boxes .data-names,
.patient-info
  .patient-address-details
  .patient-address-table-data
  .data-boxes
  .data-names,
.patient-info
  .patient-insurance-details
  .patient-insurance-table-data
  .data-boxes
  .data-names {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.patient-info .patient-details .patient-table-data .data-boxes .patient-data,
.patient-info
  .patient-address-details
  .patient-address-table-data
  .data-boxes
  .patient-data,
.patient-info
  .patient-insurance-details
  .patient-insurance-table-data
  .data-boxes
  .patient-data {
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.patient-info .patient-details .patient-table-data,
.patient-info .patient-address-details .patient-address-table-data,
.patient-info .patient-insurance-details .patient-insurance-table-data {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 75px;
}

.patient-info .patient_details_section {
  /* margin-bottom: 20px; */
}

.patient-info .patient_details_section .patient_details_head_cont {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 15px 0 14px 30px;
  border-radius: 10px 10px 0px 0px;
  background: var(--accents-yellow-1, #ffab2c);
}

.patient-info .patient_details_section .patient_details_body_cont {
  padding: 33px 47px 42px 35px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.patient-info .patient_details_section .patient_details_body_cont_inner.row_1 {
  padding-bottom: 19px;
  margin-bottom: 12px;
}

.patient-info .patient_details_section .patient_details_body_cont_inner.row_1,
.patient-info .patient_details_section .patient_details_body_cont_inner.row_2,
.patient-info .patient_details_section .patient_details_body_cont_inner.row_3,
.patient-info .patient_details_section .patient_details_body_cont_inner.row_4 {
  border-bottom: 1px solid #dad6d6;
}

.patient-info
  .patient_details_section
  .patient_details_body_cont
  .patient_details_body_cont_inner {
  display: flex;
  gap: 108px;
}

.patient-info
  .patient_details_section
  .patient_details_body_cont
  .patient_details_user_data.width_247 {
  width: 100%;
  max-width: 247px;
}

.patient-info
  .patient_details_section
  .patient_details_body_cont
  .patient_details_user_data.width_254 {
  width: 100%;
  max-width: 254px;
}

.patient-info
  .patient_details_section
  .patient_details_body_cont
  .patient_details_user_data.width_239 {
  width: 100%;
  max-width: 239px;
}

.patient-info
  .patient_details_section
  .patient_details_body_cont
  .patient_details_user_data.width_161 {
  width: 100%;
  max-width: 161px;
}

.patient-info .patient_details_section .patient_details_body_cont .data_head {
  margin-bottom: 5px;
}

.patient-info
  .patient_details_section
  .patient_details_body_cont
  .data_head
  span {
  color: var(--primary-grey-4, #acacae);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.patient-info
  .patient_details_section
  .patient_details_body_cont
  .data_cont
  span {
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.patient-info .patient_details_section .patient_details_body_cont_inner.row_2 {
  padding-bottom: 18px;
  margin-bottom: 13px;
}

.patient-info .patient_details_section .patient_details_body_cont_inner.row_3 {
  padding-bottom: 19px;
  margin-bottom: 12px;
}

.patient-info .patient_details_section .patient_details_body_cont_inner.row_4 {
  padding-bottom: 16px;
  margin-bottom: 15px;
}

.patient-info .patient_details_section .patient_details_head_cont span {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.patient-info
  .patient_details_section
  .patient_details_body_cont
  .patient_details_body_cont_inner:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.new-born .newborn-fields input[placeholder="Search for Patient"] {
  width: 391px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background-color: var(--colors-white, #fff);
  padding: 0;
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 16px;
}

.toggle-button-ref input[type="checkbox"],
.toggle-button-born input[type="checkbox"] {
  display: none;
}

.toggle-button-ref label,
.toggle-button-born label {
  display: inline-block;
  position: relative;
  width: 46px;
  height: 24px;
  cursor: pointer;
}

.toggle-button-ref label:before,
.toggle-button-born label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 46px;
  height: 24px;
  border-radius: 12px;
  background-color: #f5ab85;
  opacity: 0.8;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.toggle-button-ref label:after,
.toggle-button-born label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.toggle-button-ref input[type="checkbox"]:checked + label:before,
.toggle-button-born input[type="checkbox"]:checked + label:before {
  background-color: #f5793b;
}

.toggle-button-ref input[type="checkbox"]:checked + label:after,
.toggle-button-born input[type="checkbox"]:checked + label:after {
  -webkit-transform: translateX(22px);
          transform: translateX(22px);
}

@media (max-width: 1023px) {
  /* Your styles for screens smaller than lg */
  .modal-content {
    width: 70%;
  }

  /* Add more styles as needed */
}
