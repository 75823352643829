/* General Report Container */
.report-container {
    height: 100%;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
    background-color: #fff;
    padding: 30px; /* Increase padding for more inner spacing */
    border: 1px solid #bbb9b9; /* Ensure the border appears in print */
    overflow: hidden;
    box-sizing: border-box; /* Ensure padding is included inside the total width */
    color: #333;
    line-height: 1.6;

    padding-bottom: 2rem;
    margin-bottom: 0 !important;
  }

  .css-ypiqx9-MuiDialogContent-root{
    padding: 0 20px !important;
  }
  
  /* Header Section */
  /* .header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #c00;
  } */
  .header {
    display: flex;
    justify-content: space-between; /* This ensures that items are evenly spaced */
    align-items: center;
    /* padding-bottom: 10px; */
    /* border-bottom: 2px solid #c00; */
    width: 100%; /* Ensure full width */
}

.header-left, .header-center, .header-right {
    flex: 1; /* Allow each section to grow equally */
    text-align: center; /* Center text in each section */
}

  /* Left Section: Hospital Name, Location, Website */
  .header-left {
    text-align: left;
  }
  
  .header-left h2 {
    font-size: 18px;
    color: #000;
    margin: 0;
  }
  
  .header-left p {
    margin: 0;
    font-size: 14px;
    color: #000;
  }
  
  .header-left a {
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
  }
  
  /* Center Section: Logo and Report Title */
  .header-center {
    text-align: center;
  }
  
  .logo {
    width: 120px;
    height: auto;
    max-width: 100%; 
  }
  
  .report-title {
    font-size: 20px;
    color: #c00;
    font-weight: bold;
    margin-top: 5px;
  }
  
  /* Right Section: Arabic Text and Social Media Icons */
  .header-right {
    text-align: right;
  }
  
  .header-right h2 {
    font-size: 18px;
    color: #000;
    margin: 0;
  }
  
  .header-right p {
    margin: 0;
    font-size: 14px;
    color: #000;
  }
  .contact-info {
    display: flex;   
    align-items: end;
    justify-content: end;
}
  
  
  .contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px; /* Adds space between items */
  }
  
  .contact-item img {
    width: 20px;
    height: 20px;
    margin-right: 5px; /* Space between icon and text */
  }
  
  .contact-item a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-item span {
    font-size: 14px;
  }
  
  .social-icons {
    margin-top: 5px;
  }
  
  .social-icons a {
    margin-left: 10px;
  }
  
  .social-icons img {
    width: 24px;
    height: 24px;
  }
  
/* Patient Info Section */
.patient-info {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
  }
  
  /* Info row to display label and value */
  .info-row {
    display: flex;
    justify-content: space-between; /* Align items close together */
    margin-bottom: 4px; /* Reduced space between rows */
    gap: 20px; /* Space between the two items (label and value) */
  }
  
  /* Label container */
  .label {
    display: flex;
    align-items: center;
    margin-right: 20px; /* Optional: tweak as needed for spacing */
  }
  
  /* Label (the text before the value) */
  .label span:first-child {
    font-weight: bold;
    color: #000;
    margin-right: 5px; /* Small space between label and value */
  }
  
  /* Value (the underlined text) */
  .info-value {
    text-decoration: underline;
    color: #333;
  }
  
  
  /* Radiology Table */
  .radiology-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .radiology-table th, .radiology-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  /* Footer Section */
  .footer {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
  }
  
  /* Print Media Query */
  @media print {
    .report-container {
      border: 1px solid #bbb9b9; 
      padding: 20px; 
      width: 100%;
      /* padding-bottom: 15rem; */
      padding-bottom: 2rem !important;
      max-width: none; /* Remove width constraints for print */
      box-sizing: border-box; /* Include padding in width */
    }
  
    .header, .footer {
      page-break-before: always;
      margin-top: 0;
    }
  
    /* Ensure the border and layout are consistent on A4 */
    @page {
      size: A4;
      margin: 10mm;
    }
  }
  