.iconcolor {
    background: linear-gradient(180deg, rgba(241, 46, 46, 0.884) 0%, rgba(243, 146, 35, 0.925) 100%);
    position: relative;
  }
  
  /* .link-container {
    position: relative;
  }
  
  .tooltip-text {
    position: absolute;
    left: 100%; 
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.7); 
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 999; 
  }
  
  .link-container:hover .tooltip-text {
    opacity: 1;
    visibility: visible;
  } */
  
  
  
.custom-scrollbar {
    scrollbar-width: thin;
    /* For Firefox - makes the scrollbar thin */
    scrollbar-color: rgba(255, 255, 255, 0.4) rgba(255, 255, 255, 0.1);
    /* Thumb and track color for Firefox */
}

/* WebKit-based browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
    width: 4px;
    /* Reduces the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4);
    /* Makes the scrollbar thumb slightly more transparent */
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    /* Reduces the border size */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.6);
    /* Lighter color on hover */
}

.custom-scrollbar::-webkit-scrollbar-thumb:active {
    background: rgba(255, 255, 255, 0.8);
    /* Even lighter on active */
}