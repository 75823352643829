.eligibility_container {
  width: 100%;
  /* max-width: 1510px; */
  /* padding: 0px 60px 0px 42px; */
  margin: auto;
  box-sizing: border-box;
  font-family: sans-serif;
}

.eligibility-page {
  /* padding: 20px 24px 19px 24px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0); */
  /* background: var(--white, #fff); */
  /* box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1); */
  font-family: sans-serif;
}

.eligibility-content {
  /* display: flex;
  justify-content: space-between; */
}

.create-eligibility {
  display: flex;
  gap: 12px;
}

.eligibility-title span {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.track-eligibility span {
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.track-eligibility {
  margin-top: 4px;
}

.create-eligibility .download .btn,
.search-filter .apply-filter .btn {
  border-radius: 4px;
  border: 2px solid var(--primary-grey-1, #4d4d53);
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  gap: 3px;
  font-family: sans-serif;
}

.create-btn {
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  max-height: 40px;
  padding: 14px 24px 14px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.create-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.search-filter .search {
  position: relative;
}

.search-filter .search input[type="search"] {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="%23242429" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.5 17.5L13.875 13.875" stroke="%23242429" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 20px 20px;
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  background-color: var(--neutrals-input-fields, #f4f6f8);
  border: 0;
  height: 40px;
  width: 292px;
}

.search-filter .search input[type="search"]::-webkit-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-filter .search input[type="search"]:-ms-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-filter .search input[type="search"]::-ms-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-filter .search input[type="search"]::placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-filter {
  display: flex;
  justify-content: end;
  margin-right: 24px;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  margin: 0;
  gap: 14px;
}

.super-git-table .site-eligibility span.eligible {
  color: var(--accents-blue-1, #387ee7);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: 91px;
  height: 30px;
  border-radius: 6px;
  background: var(--accents-blue-4, #afcbf5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.super-git-table .site-eligibility span.eligible.unknown {
  border-radius: 6px;
  background: #fff0ef;
  color: var(--accents-red-1, #de5042);
}

.super-git-table .status span.completed {
  color: var(--primary-orange-1, #f5793b);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: 91px;
  height: 30px;
  border-radius: 6px;
  background: var(--primary-orange-5, #fef2ec);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal.request-eligible {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  transition: right 0.3s ease-in-out;
  display: none;
}

.modal.open {
  right: 0;
  display: block;
}

.request-eligible .modal-content {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 600px;
  height: -webkit-fill-available;
  background-color: #fff;
  padding: 23px 27px 91px 29px;
  overflow-y: auto;
}

.request-eligible .modal-content .close:before,
.benefeciary-create .benefeciary-top .back,
.patient-info .patient-top .back {
  width: 40px;
  height: 40px;
  content: "";
  background: #f2b9b3;
  border-radius: 50%;
  display: block;
  cursor: pointer;
}

.request-eligible .modal-content .close {
  display: flex;
  align-items: center;
  position: relative;
  gap: 260px;
  margin-bottom: 37px;
}

.request-eligible .modal-content .close svg {
  position: absolute;
  left: 8px;
  cursor: pointer;
}

.request-eligible .modal-content .close .bene-text {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: sans-serif;
}

.request-eligible .modal-content .search-fields input,
.request-eligible .modal-content .search-fields select {
  width: 399px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-outline, #e9ebf0);
  background: var(--colors-white, #fff);
  padding: 0;
  margin-bottom: 20px;
  padding-left: 16px;
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
}

.request-eligible .modal-content .search-fields input[type="text"]::-webkit-input-placeholder {
  color: var(--neutrals-ink, #485361);
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6000000238418579; */
}

.request-eligible .modal-content .search-fields input[type="text"]:-ms-input-placeholder {
  color: var(--neutrals-ink, #485361);
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6000000238418579; */
}

.request-eligible .modal-content .search-fields input[type="text"]::-ms-input-placeholder {
  color: var(--neutrals-ink, #485361);
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6000000238418579; */
}

.request-eligible .modal-content .search-fields input[type="text"]::placeholder,
.request-eligible .modal-content .search-fields select {
  color: var(--neutrals-ink, #485361);
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6000000238418579; */
}

.request-eligible .modal-content .search-fields input[type="date"] {
  color: var(--neutrals-ink, #485361);
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6000000238418579; */
}

.request-eligible .modal-content .search-btn {
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  text-align: center;
  width: 399px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.request-eligible .modal-content .search-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.request-eligible .modal-content .reset-btn {
  border-radius: 4px;
  border: 2px solid #242429;
  background: rgba(255, 255, 255, 0.08);
  text-align: center;
  width: 395px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.request-eligible .modal-content .reset-btn span {
  color: var(--neutrals-black, #242429);
  font-family: sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.request-eligible .modal-content .referral,
.request-eligible .modal-content .newBorn {
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 4px 32px 0px rgba(222, 227, 240, 0.72);
  padding: 8px 28px 8px 26px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.request-eligible .modal-content .referral-title,
.request-eligible .modal-content .newBorn-title {
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.toggle-button-ref input[type="checkbox"],
.toggle-button-born input[type="checkbox"] {
  display: none;
}

.toggle-button-ref label,
.toggle-button-born label {
  display: inline-block;
  position: relative;
  width: 46px;
  height: 24px;
  cursor: pointer;
}

.toggle-button-ref label:before,
.toggle-button-born label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 46px;
  height: 24px;
  border-radius: 12px;
  background-color: #f5ab85;
  opacity: 0.8;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.toggle-button-ref label:after,
.toggle-button-born label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.toggle-button-ref input[type="checkbox"]:checked + label:before,
.toggle-button-born input[type="checkbox"]:checked + label:before {
  background-color: #f5793b;
}

.toggle-button-ref input[type="checkbox"]:checked + label:after,
.toggle-button-born input[type="checkbox"]:checked + label:after {
  -webkit-transform: translateX(22px);
          transform: translateX(22px);
}

.request-eligible .modal-content .select-subs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.request-eligible .modal-content .select-subs .subs-title {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.request-eligible .modal-content .select-subs .subs-btn {
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  padding: 16px 0px;
  width: 100%;
  max-width: 253px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 17px;
}

.request-eligible .modal-content .select-subs .subs-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.request-eligible .search {
  position: relative;
  margin-bottom: 20px;
}

.request-eligible .search input[type="search"] {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="%23242429" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.5 17.5L13.875 13.875" stroke="%23242429" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 20px 20px;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-black, #242429);
  background-color: var(--colors-white, #fff);
  height: 40px;
  width: 100%;
  max-width: 736px;
}

.request-eligible .search input[type="search"]::-webkit-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.request-eligible .search input[type="search"]:-ms-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.request-eligible .search input[type="search"]::-ms-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.request-eligible .search input[type="search"]::placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input-container {
  position: relative;
  margin-bottom: 15px;
}

.input-container label {
  position: absolute;
  top: 10px;
  left: 16px;
  color: var(--neutrals-ink, #485361);
  font-size: 12px;
  font-weight: 500;
}

.request-eligible .modal-content .input-container input {
  width: 100%;
  max-width: 736px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-black, #242429);
  background: var(--colors-white, #fff);
  box-sizing: border-box;
  padding-left: 16px;
}

#request-eligible .search input {
  width: 100%;
  max-width: 736px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid var(--neutrals-black, #242429);
  background: var(--colors-white, #fff);
  box-sizing: border-box;
  padding-left: 16px;
  color: var(--neutrals-ink, #485361);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.request-eligible
  .modal-content
  .input-container
  input[type="text"]::-webkit-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-weight: 400;
}

.request-eligible
  .modal-content
  .input-container
  input[type="text"]:-ms-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-weight: 400;
}

.request-eligible
  .modal-content
  .input-container
  input[type="text"]::-ms-input-placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-weight: 400;
}

.request-eligible
  .modal-content
  .input-container
  input[type="text"]::placeholder {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-weight: 400;
}

.required:before {
  content: "*";
  display: inline-block;
  color: var(--accents-red-1, #de5042);
  vertical-align: middle;
  font-size: 18px;
  font-weight: 600;
  margin-left: 5px;
}

.request-eligible .modal-content .purpose .purpose-title {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 15px;
}

.request-eligible
  .modal-content
  .purpose
  .purpose-checks
  input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 5.5px;
  border: 1px solid #f5793b;
  background-color: #fef2ec;
  vertical-align: middle;
  margin: 0;
}

.request-eligible
  .modal-content
  .purpose
  .purpose-checks
  input[type="checkbox"]:checked {
  background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="%23FEF2EC"/><path d="M14.6668 6.5L8.25016 12.9167L5.3335 10" stroke="%23F5793B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="%23F5793B"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
}

.request-eligible .modal-content .purpose .purpose-checks label {
  margin-left: 12px;
}

.request-eligible .modal-content .purpose .purpose-checks {
  display: flex;
  align-items: center;
}

.request-eligible .modal-content .purpose {
  margin-bottom: 15px;
}

.request-eligible .modal-content .purpose .purpose-checks span {
  margin-right: 283px;
}

.request-eligible .modal-content .discovery .discovery-checks {
  margin-bottom: 15px;
}

.request-eligible .modal-content .discovery .discovery-checks label {
  color: #344054;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.request-eligible .modal-content .discovery .discovery-checks #discovery {
  display: none;
}

.request-eligible
  .modal-content
  .discovery
  .discovery-checks
  #discovery
  + label {
  position: relative;
  padding-left: 33px;
  cursor: pointer;
}

.request-eligible
  .modal-content
  .discovery
  .discovery-checks
  #discovery
  + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 20px;
  height: 20px;
  background-color: var(--primary-orange-4, #f5c3ab);
  border: 1px solid var(--primary-orange-1, #f5793b);
  border-radius: 50%;
}

.request-eligible
  .modal-content
  .discovery
  .discovery-checks
  #discovery:checked
  + label::before {
  background-color: var(--primary-orange-4, #f5c3ab);
  border: 1px solid var(--primary-orange-1, #f5793b);
}

.request-eligible
  .modal-content
  .discovery
  .discovery-checks
  #discovery:checked
  + label::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary-orange-1, #f5793b);
}

.request-eligible .modal-content .discovery .discovery-title {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 21px;
}

.request-eligible .modal-content .insurance-plan .ins-plan-title {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;
}

.insurance-plan select {
  border-radius: 8px;
  border: 1px solid var(--neutrals-black, #242429);
  background: var(--colors-white, #fff);
}

.request-eligible .modal-content .insurance-plan select {
  border-radius: 8px;
  border: 1px solid var(--neutrals-black, #242429);
  background-color: var(--colors-white, #fff);
  width: 100%;
  max-width: 736px;
  height: 40px;
  padding-left: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><g opacity='0.6'><path d='M5 7.5L10 12.5L15 7.5' stroke='%23485361' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/></g></svg>");
  background-repeat: no-repeat;
  background-position: right 20px center;
  color: var(--neutrals-black, #242429);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.request-eligible .modal-content .insurance-plan {
  margin-bottom: 40px;
}

.request-eligible .modal-content .request-eligible-btns {
  display: flex;
  align-items: center;
  gap: 20px;
}

.request-eligible .modal-content .request-eligible-btns .cancel-btn {
  width: 100%;
  max-width: 358px;
  height: 40px;
  border-radius: 5px;
  background: var(--neutrals-black, #242429);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.request-eligible .modal-content .request-eligible-btns .request-btn {
  width: 100%;
  max-width: 358px;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(83deg, #f14f3e 0%, #fab768 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.request-eligible .modal-content .request-eligible-btns .request-btn span,
.request-eligible .modal-content .request-eligible-btns .cancel-btn span {
  color: var(--neutrals-white, #fcfdff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.eligibility-page .service_to_date {
  display: flex;
  gap: 22px;
  margin-bottom: 15px;
}

.eligibility-page .service_to_date input[type="date"] {
  color: var(--neutrals-ink, #485361);
  font-family: sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* opacity: 0.6000000238418579; */
}

.eligibility-page .service_to_date input[type="date"] {
  border-radius: 8px;
  border: 1px solid var(--neutrals-black, #242429);
  background: var(--colors-white, #fff);
  width: 100%;
  max-width: 357px;
  height: 40px;
  padding-left: 16px;
}

.eligibility-page .service_to_date input#date {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'><g opacity='0.6'><path d='M15.8333 3.3335H4.16667C3.24619 3.3335 2.5 4.07969 2.5 5.00016V16.6668C2.5 17.5873 3.24619 18.3335 4.16667 18.3335H15.8333C16.7538 18.3335 17.5 17.5873 17.5 16.6668V5.00016C17.5 4.07969 16.7538 3.3335 15.8333 3.3335Z' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M13.3333 1.66626V4.99959' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M6.66724 1.66626V4.99959' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/><path d='M2.5 8.33325H17.5' stroke='%23485361' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></g></svg>");
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.eligibility-page .service_to_date input#date::-moz-calendar-picker-indicator {
  display: none;
}

.eligibility-page
  .service_to_date
  input#date::-webkit-calendar-picker-indicator {
  display: none;
}

.eligibility_Json,
.eligibility_Details {
  font-family: sans-serif;
}

.eligibility_Json .eligibility_josn_top,
.eligibility_Details .eligibility_details_top {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 20px 0 19px 20px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  margin-bottom: 24px;
}

.eligibility_Json .eligibility_josn_top .back,
.eligibility_Details .eligibility_details_top .back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  content: "";
  background: #f2b9b3;
  border-radius: 50%;
  cursor: pointer;
}

.eligibility_Json .eligibility_josn_top .eligibility_josn_title span,
.eligibility_Details .eligibility_details_top .eligibility_details_top span {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.eligibility_Json .eligibility_josn_top .eligibility_josn_content span,
.eligibility_Details
  .eligibility_details_top
  .eligibility_details_content
  span {
  color: var(--gray-500, #667085);
  font-size: 16px;
  font-weight: 400;
}

.eligibility_Json .super-git-table .table-super-git tbody td {
  color: var(--neutrals-ink, #485361);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.eligibility_Json .super-git-table .table-super-git tbody td {
  color: var(--neutrals-ink, #485361);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.eligibility_Json .super-git-table thead th {
  color: var(--neutrals-black, #242429);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.eligibility_Json .eligibility_Json_nav_menu ul,
.eligibility_Details .eligibility_details_nav_menu ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 10px;
}

.eligibility_Json .eligibility_Json_nav_menu ul li,
.eligibility_Details .eligibility_details_nav_menu ul li {
  padding: 6px 16px;
  color: var(--primary-orange-2, #f5925b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}

.eligibility_Json .eligibility_Json_nav_menu ul li.active,
.eligibility_Details .eligibility_details_nav_menu ul li.active {
  color: var(--primary-orange-1, #f5793b);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  background: var(--white, #fff);
  box-shadow: 0px 4px 0px 0px #f5793b;
}

.eligibility_Json .eligibility_Json_nav_menu,
.eligibility_Details .eligibility_details_nav_menu {
  border-bottom: 1px solid #e1e5e8;
  margin-bottom: 29px;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_head_cont {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 15px 0 14px 30px;
  border-radius: 10px 10px 0px 0px;
  background: var(--accents-yellow-1, #ffab2c);
}

.eligibility_details_head_cont span {
  color: #344054;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont
  .eligibility_details_body_cont_inner {
  display: flex;
  gap: 108px;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont
  .eligibility_details_user_data.width_247 {
  width: 100%;
  max-width: 247px;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont
  .eligibility_details_user_data.width_254 {
  width: 100%;
  max-width: 254px;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont
  .eligibility_details_user_data.width_239 {
  width: 100%;
  max-width: 239px;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont
  .eligibility_details_user_data.width_161 {
  width: 100%;
  max-width: 161px;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont {
  padding: 33px 47px 42px 35px;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont_inner.row_1,
.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont_inner.row_2 {
  border-bottom: 1px solid #dad6d6;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont_inner.row_1 {
  padding-bottom: 19px;
  margin-bottom: 12px;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont_inner.row_2 {
  padding-bottom: 18px;
  margin-bottom: 13px;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont
  .data_head
  span {
  color: var(--primary-grey-4, #acacae);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont
  .data_head {
  margin-bottom: 5px;
}

.eligibility_Details
  .eligibility_details_section
  .eligibility_details_body_cont
  .data_cont
  span {
  color: var(--neutrals-ink, #485361);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.eligibility_Details .eligibility_details_section {
  margin-bottom: 20px;
}

.eligibility_Details
  .eligibility_details_section.accordians
  .eligibility_details_body_cont {
  padding: 27px 30px 72px 30px;
}

.eligibility_Details
  .eligibility_details_section.accordians
  .eligibility_details_body_cont
  .items_data_accordians {
  padding: 24px 33px 22px 32px;
  border-radius: 10px;
  border: 1px solid #e1e5e8;
  background: #fff;
  margin-bottom: 20px;
}

.eligibility_Details
  .eligibility_details_section.accordians
  .eligibility_details_body_cont
  .items_data_accordians:last-child {
  margin-bottom: 0;
}

.eligibility_Details
  .eligibility_details_section.accordians
  .eligibility_details_body_cont
  .custom-summary {
  font-weight: bold;
  cursor: pointer;
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eligibility_Details
  .eligibility_details_section.accordians
  .eligibility_details_body_cont
  .custom-summary
  span {
  color: var(--accents-blue-1, #387ee7);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.eligibility_Details
  .eligibility_details_section.accordians
  .eligibility_details_body_cont
  .expand-icon {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.eligibility_Details
  .eligibility_details_section.accordians
  .eligibility_details_body_cont_inner.row_1 {
  padding-bottom: 19px;
  margin-bottom: 12px;
  gap: 95px;
}

.eligibility_Details
  .eligibility_details_section.accordians
  .eligibility_details_body_cont_inner.row_2 {
  padding-bottom: 25px;
  margin-bottom: 22px;
}

details[open] .custom-summary {
  margin-bottom: 32px;
}

.eligibility_Details
  .eligibility_details_section.accordians
  .eligibility_details_body_cont
  .benefits-head {
  margin-bottom: 16px;
  color: var(--primary-orange-1, #f5793b);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.items_data_accordians details[open] {
  padding-bottom: 19px;
}
